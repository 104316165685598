{
  "type": "FeatureCollection",
  "name": "upper_level_flows",
  "crs": {
    "type": "name",
    "properties": {
      "name": "urn:ogc:def:crs:EPSG::3857"
    }
  },
  "features": [
    {
      "type": "Feature",
      "properties": {
        "id": "evolving_planet"
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -9753452.917340655,
              5141018.536599553
            ],
            [
              -9753443.800053287,
              5141018.733865103
            ],
            [
              -9753372.611154342,
              5141020.295263132
            ],
            [
              -9753372.235940496,
              5141003.188119226
            ],
            [
              -9753360.343167216,
              5141003.448965414
            ],
            [
              -9753360.927149085,
              5141030.074480134
            ],
            [
              -9753346.27437401,
              5141030.395861904
            ],
            [
              -9753344.79449657,
              5140962.923735097
            ],
            [
              -9753358.486908596,
              5140962.623417136
            ],
            [
              -9753359.005755201,
              5140983.728923803
            ],
            [
              -9753370.031911802,
              5140983.487085262
            ],
            [
              -9753370.096915571,
              5140989.001097969
            ],
            [
              -9753382.365076538,
              5141000.197967534
            ],
            [
              -9753382.465660445,
              5141005.825956087
            ],
            [
              -9753441.679927025,
              5141004.756591206
            ],
            [
              -9753441.524555137,
              5140996.969505
            ],
            [
              -9753382.753430802,
              5140998.025195117
            ],
            [
              -9753372.745620731,
              5140989.2084019175
            ],
            [
              -9753372.583002428,
              5140983.320136213
            ],
            [
              -9753451.282049444,
              5140982.068689609
            ]
          ]
        ]
      },
      "id": "evolving_planet"
    },
    {
      "type": "Feature",
      "properties": {
        "id": "restoring_earth"
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -9753514.380928978,
              5141021.492198718
            ],
            [
              -9753518.679737257,
              5141015.469339833
            ],
            [
              -9753598.686611425,
              5141013.695165955
            ]
          ]
        ]
      },
      "id": "restoring_earth"
    },
    {
      "type": "Feature",
      "properties": {
        "id": "pacific"
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -9753513.95889204,
              5140945.137056733
            ],
            [
              -9753577.697528655,
              5140943.9441779535
            ],
            [
              -9753577.925654542,
              5140956.133541389
            ],
            [
              -9753603.71979548,
              5140955.593069016
            ],
            [
              -9753603.859955082,
              5140968.594200067
            ],
            [
              -9753577.966041328,
              5140969.136539705
            ],
            [
              -9753578.268863581,
              5140979.80508892
            ],
            [
              -9753522.719157623,
              5140980.844710558
            ]
          ]
        ]
      },
      "id": "pacific"
    },
    {
      "type": "Feature",
      "properties": {
        "id": "china"
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -9753450.562027287,
              5140947.888451408
            ],
            [
              -9753360.825392326,
              5140949.359545126
            ]
          ]
        ]
      },
      "id": "china"
    },
    {
      "type": "Feature",
      "properties": {
        "id": "jades"
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -9753464.289922126,
              5140905.184086834
            ],
            [
              -9753464.063821392,
              5140893.354907503
            ],
            [
              -9753457.386832016,
              5140893.48253023
            ],
            [
              -9753457.48492825,
              5140898.614747139
            ],
            [
              -9753448.027833499,
              5140898.795508291
            ],
            [
              -9753447.782740153,
              5140885.972669907
            ],
            [
              -9753456.73679947,
              5140885.80152368
            ],
            [
              -9753456.812107291,
              5140889.7414923925
            ],
            [
              -9753463.649166377,
              5140889.610810122
            ],
            [
              -9753463.579437716,
              5140885.962733213
            ]
          ]
        ]
      },
      "id": "jades"
    },
    {
      "type": "Feature",
      "properties": {
        "id": "gems"
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -9753501.803129716,
              5140903.561905522
            ],
            [
              -9753501.623190537,
              5140894.533652699
            ],
            [
              -9753507.896323921,
              5140894.408624912
            ],
            [
              -9753507.98201351,
              5140898.708007033
            ],
            [
              -9753518.165604318,
              5140898.505041171
            ],
            [
              -9753517.874998817,
              5140883.924226176
            ],
            [
              -9753507.449295307,
              5140884.132017505
            ],
            [
              -9753507.544179978,
              5140888.892752854
            ],
            [
              -9753501.573076572,
              5140889.011760982
            ],
            [
              -9753501.501054924,
              5140885.398153081
            ]
          ]
        ]
      },
      "id": "gems"
    },
    {
      "type": "Feature",
      "properties": {
        "id": "plants"
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -9753450.038483743,
              5140912.777108131
            ],
            [
              -9753351.329736616,
              5140914.39528579
            ],
            [
              -9753351.792164888,
              5140942.603384688
            ]
          ]
        ]
      },
      "id": "plants"
    },
    {
      "type": "Feature",
      "properties": {
        "id": "comer_gallery"
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -9753609.76457138,
              5140985.835593981
            ],
            [
              -9753624.058665572,
              5140985.570907383
            ],
            [
              -9753624.438665276,
              5141006.092352868
            ],
            [
              -9753610.834111579,
              5141006.344271106
            ]
          ]
        ]
      },
      "id": "comer_gallery"
    },
    {
      "type": "Feature",
      "properties": {
        "id": "marae_gallery"
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -9753623.679754268,
              5140967.074199479
            ],
            [
              -9753623.9093627,
              5140979.473314544
            ],
            [
              -9753608.715375543,
              5140979.754678782
            ]
          ]
        ]
      },
      "id": "marae_gallery"
    }
  ]
}
