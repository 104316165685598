{
"type": "FeatureCollection",
"name": "main_level_areas",
"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::3857" } },
"features": [
{ "type": "Feature", "id": "mastaba", "properties": { "closed": 0, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753514.858574939892, 5140930.035147480667 ], [ -9753514.711798729375, 5140921.183687690645 ], [ -9753508.722089095041, 5140921.283009937964 ], [ -9753508.868865305558, 5140930.134469727986 ], [ -9753514.858574939892, 5140930.035147480667 ] ] ] ] } },
{ "type": "Feature", "id": "sci_hub", "properties": { "closed": 0, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753461.184043746442, 5140944.971589189954 ], [ -9753460.97767418623, 5140932.526303620078 ], [ -9753448.931702591479, 5140932.726051697508 ], [ -9753449.138072151691, 5140945.171337267384 ], [ -9753461.184043746442, 5140944.971589189954 ] ] ] ] } },
{ "type": "Feature", "id": "africa", "properties": { "closed": 0, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753525.670325826854, 5140971.709801921621 ], [ -9753525.302373172715, 5140937.256064519286 ], [ -9753605.267641590908, 5140935.778706549667 ], [ -9753605.622377084568, 5140954.343975422904 ], [ -9753594.533212710172, 5140954.548938748427 ], [ -9753594.826635459438, 5140970.432230850682 ], [ -9753525.670325826854, 5140971.709801921621 ] ] ] ] } },
{ "type": "Feature", "id": "", "properties": { "closed": 0, "label": "Bird Habitats" }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753565.572200978175, 5140936.512132876553 ], [ -9753565.274356497452, 5140920.389487648383 ], [ -9753604.95958208479, 5140919.656250030734 ], [ -9753605.267641590908, 5140935.778706549667 ], [ -9753565.572200978175, 5140936.512132876553 ] ] ] ] } },
{ "type": "Feature", "id": "animals", "properties": { "closed": 0, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753604.95958208479, 5140919.656250030734 ], [ -9753524.966332426295, 5140921.134124916978 ], [ -9753524.627231042832, 5140900.636006940156 ], [ -9753604.60363932699, 5140899.150786129758 ], [ -9753604.95958208479, 5140919.656250030734 ] ] ] ] } },
{ "type": "Feature", "id": "mammals", "properties": { "closed": 0, "label": "" }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753604.515620952472, 5140894.471893980168 ], [ -9753624.759478114545, 5140894.12071346119 ], [ -9753627.263055292889, 5141029.316713675857 ], [ -9753607.05884754099, 5141029.665062086657 ], [ -9753604.515620952472, 5140894.471893980168 ] ] ], [ [ [ -9753606.274463273585, 5140988.471249494702 ], [ -9753539.710568096489, 5140989.705241876654 ], [ -9753525.799378724769, 5140989.968110671267 ], [ -9753525.670325826854, 5140971.709801921621 ], [ -9753605.925863210112, 5140970.22708161734 ], [ -9753606.274463273585, 5140988.471249494702 ] ] ] ] } },
{ "type": "Feature", "id": "rice_gallery", "properties": { "closed": 0, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753594.826635459438, 5140970.432230850682 ], [ -9753594.533212710172, 5140954.548938748427 ], [ -9753605.622377084568, 5140954.343975422904 ], [ -9753605.925863210112, 5140970.22708161734 ], [ -9753594.826635459438, 5140970.432230850682 ] ] ] ] } },
{ "type": "Feature", "id": "birds", "properties": { "closed": 0, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753527.201100433245, 5141027.451786724851 ], [ -9753526.807377506047, 5141006.139137038961 ], [ -9753606.585495740175, 5141004.749297419563 ], [ -9753606.976939864457, 5141025.977928204462 ], [ -9753527.201100433245, 5141027.451786724851 ] ] ] ] } },
{ "type": "Feature", "id": "nature_walk", "properties": { "closed": 0, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753518.789369285107, 5140990.142869132571 ], [ -9753606.274463273585, 5140988.471249494702 ], [ -9753606.585495740175, 5141004.749297419563 ], [ -9753519.100401746109, 5141006.420917057432 ], [ -9753519.144361209124, 5141008.721558735706 ], [ -9753507.739608235657, 5141008.939474815503 ], [ -9753507.330137006938, 5140987.509582086466 ], [ -9753518.734889980406, 5140987.291666006669 ], [ -9753518.789369285107, 5140990.142869132571 ] ] ] ] } },
{ "type": "Feature", "id": "fm_store", "properties": { "closed": 0, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753396.531488653272, 5140923.725139087066 ], [ -9753396.191173151135, 5140902.877636582591 ], [ -9753441.873637080193, 5140902.131914134137 ], [ -9753442.21395258233, 5140922.979416638613 ], [ -9753396.531488653272, 5140923.725139087066 ] ] ] ] } },
{ "type": "Feature", "id": "founders", "properties": { "closed": 1, "label": "Founders Room" }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753445.578118955716, 5140902.073252767324 ], [ -9753445.283580465242, 5140883.290759662166 ], [ -9753449.225864904001, 5140883.154818823561 ], [ -9753456.610879745334, 5140883.15477462206 ], [ -9753459.914564806968, 5140883.087891280651 ], [ -9753460.292563183233, 5140901.759021651931 ], [ -9753445.578118955716, 5140902.073252767324 ] ] ] ] } },
{ "type": "Feature", "id": "pawnee_lodge", "properties": { "closed": 0, "label": "" }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753362.754906637594, 5140940.078014170751 ], [ -9753392.246890010312, 5140939.591681121849 ], [ -9753392.543315365911, 5140957.750502436422 ], [ -9753363.051446784288, 5140958.243867472745 ], [ -9753362.754906637594, 5140940.078014170751 ] ] ] ] } },
{ "type": "Feature", "id": "field_bistro", "properties": { "closed": 0, "label": "Field Bistro" }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753449.413015797734, 5141000.869047611021 ], [ -9753462.787529891357, 5141000.651824035682 ], [ -9753463.215160027146, 5141026.981126775034 ], [ -9753459.215138753876, 5141027.046093540266 ], [ -9753459.404078057036, 5141038.56858327426 ], [ -9753469.355711735785, 5141038.374720280059 ], [ -9753469.614195728675, 5141046.840071007609 ], [ -9753462.978697754443, 5141047.013395062648 ], [ -9753459.602682564408, 5141047.076818367466 ], [ -9753452.261877061799, 5141047.212759209797 ], [ -9753448.410219844431, 5141047.258072831668 ], [ -9753447.979740509763, 5141028.996686282568 ], [ -9753449.840645933524, 5141027.198350350372 ], [ -9753449.413015797734, 5141000.869047611021 ] ] ] ] } },
{ "type": "Feature", "id": "field_bistro_rooms", "properties": { "closed": 1, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753433.499503914267, 5141029.234067210928 ], [ -9753433.165872102603, 5141008.882526771165 ], [ -9753443.438395854086, 5141008.714124741964 ], [ -9753443.772027665749, 5141029.065665181726 ], [ -9753433.499503914267, 5141029.234067210928 ] ] ] ] } },
{ "type": "Feature", "id": "field_bistro_stairs", "properties": { "closed": 2, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753447.979740509763, 5141028.996686282568 ], [ -9753443.772027665749, 5141029.065665181726 ], [ -9753443.438395854086, 5141008.714124741964 ], [ -9753449.539005365223, 5141008.626259212382 ], [ -9753449.840645933524, 5141027.198350350372 ], [ -9753447.979740509763, 5141028.996686282568 ] ] ] ] } },
{ "type": "Feature", "id": "pawnee_lodge_behind1", "properties": { "closed": 2, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753363.323655961081, 5140974.919221705757 ], [ -9753363.051446784288, 5140958.243867472745 ], [ -9753385.714674208313, 5140957.861973459832 ], [ -9753385.986883385107, 5140974.537327692844 ], [ -9753363.323655961081, 5140974.919221705757 ] ] ] ] } },
{ "type": "Feature", "id": "hall_7", "properties": { "closed": 0, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753392.246890010312, 5140939.591681121849 ], [ -9753434.541683692485, 5140938.894225285389 ], [ -9753442.729595974088, 5140938.760565463454 ], [ -9753443.026136120781, 5140965.281235251576 ], [ -9753385.853386871517, 5140966.359419478104 ], [ -9753385.714674208313, 5140957.861973459832 ], [ -9753392.543315365911, 5140957.750502436422 ], [ -9753392.246890010312, 5140939.591681121849 ] ] ] ] } },
{ "type": "Feature", "id": "museum_store_behind", "properties": { "closed": 2, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753368.157615503296, 5140924.188315376639 ], [ -9753362.675038000569, 5140924.277813200839 ], [ -9753362.633718824014, 5140917.679486448877 ], [ -9753367.995785279199, 5140917.545662513934 ], [ -9753367.813858773559, 5140903.511129395105 ], [ -9753396.195996643975, 5140903.113197189756 ], [ -9753396.532466582954, 5140923.725123123266 ], [ -9753368.157615503296, 5140924.188315376639 ] ] ] ] } },
{ "type": "Feature", "id": "kitchen_catering", "properties": { "closed": 2, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753520.005730371922, 5140881.840724009089 ], [ -9753520.474368909374, 5140902.79774366878 ], [ -9753506.010526068509, 5140903.121182509698 ], [ -9753505.541887531057, 5140882.164162850007 ], [ -9753508.794099438936, 5140882.098321585916 ], [ -9753516.444335738197, 5140881.947530623525 ], [ -9753520.005730371922, 5140881.840724009089 ] ] ] ] } },
{ "type": "Feature", "id": "server_room", "properties": { "closed": 2, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753565.572199994698, 5140936.512079652399 ], [ -9753519.34720152989, 5140937.366078414954 ], [ -9753519.049357049167, 5140921.243433186784 ], [ -9753565.274356497452, 5140920.389487648383 ], [ -9753565.572199994698, 5140936.512079652399 ] ] ] ] } },
{ "type": "Feature", "id": "northwest_stairs", "properties": { "closed": 2, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753522.985100500286, 5141045.864679183811 ], [ -9753519.337354550138, 5141046.023276833817 ], [ -9753512.064519461244, 5141046.091247251257 ], [ -9753508.502986734733, 5141046.158155770041 ], [ -9753508.605609586462, 5141054.202960781753 ], [ -9753500.729767283425, 5141054.257276935503 ], [ -9753500.695658367127, 5141037.372917204164 ], [ -9753511.74852807261, 5141037.271514729597 ], [ -9753511.650369539857, 5141025.684425763786 ], [ -9753522.646864002571, 5141025.591237537563 ], [ -9753522.985100500286, 5141045.864679183811 ] ] ] ] } },
{ "type": "Feature", "id": "coat_check", "properties": { "closed": 1, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753447.983469244093, 5140927.816000684164 ], [ -9753447.851483073086, 5140919.730615139939 ], [ -9753467.841340588406, 5140919.404299871996 ], [ -9753467.973326759413, 5140927.489685416222 ], [ -9753447.983469244093, 5140927.816000684164 ] ] ] ] } },
{ "type": "Feature", "id": "", "properties": { "closed": 1, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753525.670325826854, 5140971.709801921621 ], [ -9753525.722984420136, 5140979.159900818951 ], [ -9753512.25804509595, 5140979.404434448108 ], [ -9753512.120521198958, 5140971.960116586648 ], [ -9753525.670325826854, 5140971.709801921621 ] ] ] ] } },
{ "type": "Feature", "id": "hall_5", "properties": { "closed": 0, "label": "" }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753443.026136120781, 5140965.281235251576 ], [ -9753385.853386871517, 5140966.359419478104 ], [ -9753385.986883385107, 5140974.537327692844 ], [ -9753386.278030088171, 5140992.523217099719 ], [ -9753443.371153401211, 5140991.587663408369 ], [ -9753443.026136120781, 5140965.281235251576 ] ] ] ] } },
{ "type": "Feature", "id": "", "properties": { "closed": 1, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753443.371153401211, 5140991.587663408369 ], [ -9753449.260732198134, 5140991.492905299179 ], [ -9753449.141648842022, 5140984.091419409961 ], [ -9753443.252070045099, 5140984.18617751915 ], [ -9753443.371153401211, 5140991.587663408369 ] ] ] ] } },
{ "type": "Feature", "id": "", "properties": { "closed": 1, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753443.129772538319, 5140973.183169770986 ], [ -9753443.22884138301, 5140980.736842043698 ], [ -9753448.874014250934, 5140980.662803783081 ], [ -9753448.774945406243, 5140973.109131510369 ], [ -9753443.129772538319, 5140973.183169770986 ] ] ] ] } },
{ "type": "Feature", "id": "pawnee_lodge_behind2", "properties": { "closed": 1, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753385.986883385107, 5140974.537327692844 ], [ -9753363.323655961081, 5140974.919221705757 ], [ -9753363.626581868157, 5140992.896143239923 ], [ -9753386.289809292182, 5140992.51424922701 ], [ -9753385.986883385107, 5140974.537327692844 ] ] ] ] } },
{ "type": "Feature", "id": "pawnee_lodge", "properties": { "closed": 0, "label": "Pawnee Lodge" }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753392.246890010312, 5140939.591681121849 ], [ -9753362.754906637594, 5140940.078014170751 ], [ -9753392.246775219217, 5140939.584649134427 ], [ -9753392.246890010312, 5140939.591681121849 ] ] ] ] } },
{ "type": "Feature", "id": "hall_7", "properties": { "closed": 0, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753434.541683692485, 5140938.894225285389 ], [ -9753392.246890010312, 5140939.591681121849 ], [ -9753392.246775219217, 5140939.584649134427 ], [ -9753434.541683692485, 5140938.894225285389 ] ] ] ] } },
{ "type": "Feature", "id": "hall_8", "properties": { "closed": 0, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753362.675038000569, 5140924.277813200839 ], [ -9753434.284022221342, 5140923.108865153044 ], [ -9753434.541703093797, 5140938.894224965014 ], [ -9753362.754906637594, 5140940.078014170751 ], [ -9753362.675038000569, 5140924.277813200839 ] ] ] ] } },
{ "type": "Feature", "id": "americas", "properties": { "closed": 0, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753362.675038000569, 5140924.277813200839 ], [ -9753362.754906637594, 5140940.078014170751 ], [ -9753363.617114542052, 5140992.896300278604 ], [ -9753449.260732198134, 5140991.492905299179 ], [ -9753449.539005365223, 5141008.626259212382 ], [ -9753433.166030395776, 5141008.892182605341 ], [ -9753433.499503914267, 5141029.234067210928 ], [ -9753363.973622489721, 5141030.333437904716 ], [ -9753364.081583721563, 5141034.230408727191 ], [ -9753343.803741354495, 5141034.615574449301 ], [ -9753341.311492560431, 5140899.411077931523 ], [ -9753361.521346857771, 5140899.069936143234 ], [ -9753361.622032342479, 5140903.579930437729 ], [ -9753367.813858773559, 5140903.511129395105 ], [ -9753367.995785279199, 5140917.545662513934 ], [ -9753362.633718824014, 5140917.679486448877 ], [ -9753362.675038000569, 5140924.277813200839 ] ] ] ] } }
]
}
