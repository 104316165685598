{
  "type": "FeatureCollection",
  "name": "main_level_amenities",
  "crs": {
    "type": "name",
    "properties": {
      "name": "urn:ogc:def:crs:EPSG::3857"
    }
  },
  "features": [
    {
      "type": "Feature",
      "properties": {
        "type": "restroom"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          -9753519.314022817,
          5140975.504749831
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "type": "stairs_up_down"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          -9753523.618715234,
          5141019.97026769
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "type": "stairs_up"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          -9753507.97188618,
          5141031.658923046
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "type": "stairs_up"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          -9753463.556859698,
          5141032.314011931
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "type": "stairs_down"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          -9753454.580249833,
          5140965.157691745
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "type": "stairs_down"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          -9753513.731493384,
          5140963.912402408
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "type": "elevator"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          -9753521.183278782,
          5140952.631874231
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "type": "elevator"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          -9753446.954169054,
          5140953.873326148
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "type": "store"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          -9753437.78256169,
          5140913.12310745
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "type": "atm"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          -9753485.743257666,
          5140885.145879988
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "type": "stairs_up"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          -9753489.154335998,
          5140900.914789766
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "type": "stairs_up_down"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          -9753521.35559553,
          5140907.215455098
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "type": "stairs_up"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          -9753603.682074636,
          5141018.4767636815
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "type": "stairs_up"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          -9753364.858743615,
          5140910.2812014595
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "type": "restaurant"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          -9753456.480729613,
          5141016.167854316
        ]
      }
    }
  ]
}
