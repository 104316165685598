{
  "type": "FeatureCollection",
  "name": "upper_level_outline",
  "crs": {
    "type": "name",
    "properties": {
      "name": "urn:ogc:def:crs:EPSG::3857"
    }
  },
  "features": [
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -9753499.119832681,
                5140882.252865437
              ],
              [
                -9753498.99721111,
                5140875.334965191
              ],
              [
                -9753504.27525736,
                5140875.241410456
              ],
              [
                -9753504.397878932,
                5140882.159310702
              ],
              [
                -9753505.75459869,
                5140882.135262494
              ],
              [
                -9753520.005730372,
                5140881.840724009
              ],
              [
                -9753520.436209707,
                5140900.713844343
              ],
              [
                -9753604.649909299,
                5140899.149926858
              ],
              [
                -9753604.515620952,
                5140894.47189398
              ],
              [
                -9753624.759478115,
                5140894.120713461
              ],
              [
                -9753627.263055293,
                5141029.316713676
              ],
              [
                -9753607.058847541,
                5141029.665062087
              ],
              [
                -9753607.063939754,
                5141025.976314691
              ],
              [
                -9753522.679233605,
                5141027.535322215
              ],
              [
                -9753522.9851005,
                5141045.864679184
              ],
              [
                -9753500.989227334,
                5141046.23173022
              ],
              [
                -9753500.836293887,
                5141037.067051736
              ],
              [
                -9753470.057296634,
                5141037.612014778
              ],
              [
                -9753470.272536302,
                5141046.74270805
              ],
              [
                -9753448.410219844,
                5141047.258072832
              ],
              [
                -9753447.97974051,
                5141028.996686283
              ],
              [
                -9753363.97362249,
                5141030.378862222
              ],
              [
                -9753364.081583722,
                5141034.230408727
              ],
              [
                -9753343.803741354,
                5141034.615574449
              ],
              [
                -9753342.425458858,
                5140959.843749279
              ],
              [
                -9753341.31149256,
                5140899.4110779315
              ],
              [
                -9753361.521364525,
                5140899.071225817
              ],
              [
                -9753361.622032342,
                5140903.625476609
              ],
              [
                -9753445.578118956,
                5140902.073252767
              ],
              [
                -9753445.283580465,
                5140883.290759662
              ],
              [
                -9753459.738623409,
                5140882.9509075545
              ],
              [
                -9753461.060609734,
                5140882.927475005
              ],
              [
                -9753460.9388513,
                5140876.0582701815
              ],
              [
                -9753466.397806836,
                5140875.961508783
              ],
              [
                -9753466.51956527,
                5140882.830713606
              ],
              [
                -9753499.119832681,
                5140882.252865437
              ]
            ]
          ]
        ]
      }
    }
  ]
}
