{
  "type": "FeatureCollection",
  "name": "ground_level_flows",
  "crs": {
    "type": "name",
    "properties": {
      "name": "urn:ogc:def:crs:EPSG::3857"
    }
  },
  "features": [
    {
      "type": "Feature",
      "properties": {
        "id": "egypt"
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -9753507.542852473,
              5140945.093267804
            ],
            [
              -9753507.634150794,
              5140945.090979627
            ],
            [
              -9753507.186258966,
              5140927.220087335
            ],
            [
              -9753465.188938258,
              5140928.272651283
            ],
            [
              -9753465.61353363,
              5140945.214014382
            ],
            [
              -9753483.68979099,
              5140944.760975551
            ],
            [
              -9753484.031299507,
              5140958.387171692
            ]
          ]
        ]
      },
      "id": "egypt"
    },
    {
      "type": "Feature",
      "properties": {
        "id": "underground"
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -9753453.03748549,
              5140951.177420398
            ],
            [
              -9753453.043793287,
              5140951.177272436
            ],
            [
              -9753452.824273182,
              5140941.818809059
            ],
            [
              -9753428.12497743,
              5140942.39817681
            ],
            [
              -9753428.39510019,
              5140953.913905788
            ],
            [
              -9753376.78726691,
              5140955.124463159
            ],
            [
              -9753376.19975078,
              5140930.077790407
            ],
            [
              -9753427.835392537,
              5140928.866580737
            ],
            [
              -9753428.031053465,
              5140937.207892615
            ],
            [
              -9753452.53685091,
              5140936.633063724
            ]
          ]
        ]
      },
      "id": "underground"
    },
    {
      "type": "Feature",
      "properties": {
        "id": "simpson_theater"
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -9753559.497490223,
              5140939.913828289
            ],
            [
              -9753613.960944155,
              5140939.076644539
            ],
            [
              -9753614.05008613,
              5140949.069175865
            ],
            [
              -9753600.276121704,
              5140949.2264196295
            ]
          ]
        ]
      },
      "id": "simpson_theater"
    },
    {
      "type": "Feature",
      "properties": {
        "id": "simpson_theater"
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -9753600.796260199,
              5140973.980160126
            ],
            [
              -9753614.407453712,
              5140973.825097343
            ],
            [
              -9753614.568374816,
              5140984.695413291
            ],
            [
              -9753560.43143188,
              5140985.527578088
            ]
          ]
        ]
      },
      "id": "simpson_theater"
    }
  ]
}
