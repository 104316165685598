{
"type": "FeatureCollection",
"name": "upper_level_labels",
"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::3857" } },
"features": [
{ "type": "Feature", "id": "restoring_earth", "properties": { }, "geometry": { "type": "Point", "coordinates": [ -9753548.230638964101672, 5141014.814040667377412 ] } },
{ "type": "Feature", "id": "pacific", "properties": { }, "geometry": { "type": "Point", "coordinates": [ -9753547.954493625089526, 5140980.372427205555141 ] } },
{ "type": "Feature", "id": "evolving_planet", "properties": { }, "geometry": { "type": "Point", "coordinates": [ -9753418.095792701467872, 5141019.297640950419009 ] } },
{ "type": "Feature", "id": "china", "properties": { }, "geometry": { "type": "Point", "coordinates": [ -9753424.314138172194362, 5140948.318745064549148 ] } },
{ "type": "Feature", "id": "plants", "properties": { }, "geometry": { "type": "Point", "coordinates": [ -9753423.288191638886929, 5140913.215637910179794 ] } },
{ "type": "Feature", "id": "jades", "properties": { }, "geometry": { "type": "Point", "coordinates": [ -9753444.028104778379202, 5140892.304086741060019 ] } },
{ "type": "Feature", "id": "gems", "properties": { }, "geometry": { "type": "Point", "coordinates": [ -9753521.822038451209664, 5140891.065686972811818 ] } },
{ "type": "Feature", "id": "hoffman", "properties": { }, "geometry": { "type": "Point", "coordinates": [ -9753468.875462546944618, 5140926.75694530736655 ] } },
{ "type": "Feature", "id": "comer_gallery", "properties": { }, "geometry": { "type": "Point", "coordinates": [ -9753622.33637828938663, 5140995.532506265677512 ] } },
{ "type": "Feature", "id": "marae_gallery", "properties": { }, "geometry": { "type": "Point", "coordinates": [ -9753622.106315340846777, 5140958.046617023646832 ] } },
{ "type": "Feature", "id": "sue", "properties": { }, "geometry": { "type": "Point", "coordinates": [ -9753397.065036259591579, 5141000.451720537617803 ] } },
{ "type": "Feature", "id": "brooker_gallery", "properties": { }, "geometry": { "type": "Point", "coordinates": [ -9753459.233758240938187, 5141042.435043804347515 ] } },
{ "type": "Feature", "id": "searle_lounge", "properties": { }, "geometry": { "type": "Point", "coordinates": [ -9753485.465985897928476, 5141022.216576614417136 ] } },
{ "type": "Feature", "id": "maori_house", "properties": { }, "geometry": { "type": "Point", "coordinates": [ -9753618.637122683227062, 5140946.469258027151227 ] } },
{ "type": "Feature", "id": "conservation_lab", "properties": { }, "geometry": { "type": "Point", "coordinates": [ -9753590.745933759957552, 5140944.082844876684248 ] } }
]
}
