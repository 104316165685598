{
  "type": "FeatureCollection",
  "name": "upper_level_easter",
  "crs": {
    "type": "name",
    "properties": {
      "name": "urn:ogc:def:crs:EPSG::3857"
    }
  },
  "features": [
    {
      "type": "Feature",
      "properties": {
        "id": "tardigrade"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          -9753367.994291404,
          5140923.623005061
        ]
      },
      "id": "tardigrade"
    },
    {
      "type": "Feature",
      "properties": {
        "id": "eye_of_the_lion"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          -9753455.007197991,
          5140963.491790502
        ]
      },
      "id": "eye_of_the_lion"
    }
  ]
}
