{
  "type": "FeatureCollection",
  "name": "main_level_flows",
  "crs": {
    "type": "name",
    "properties": {
      "name": "urn:ogc:def:crs:EPSG::3857"
    }
  },
  "features": [
    {
      "type": "Feature",
      "id": "birds",
      "properties": {},
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -9753513.527798124,
              5141015.752363338
            ],
            [
              -9753597.683908846,
              5141014.163532016
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "id": "animals",
      "properties": {},
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -9753512.605190456,
              5140908.696684377
            ],
            [
              -9753595.443199012,
              5140907.166365803
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "id": "africa",
      "properties": {},
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -9753522.492185583,
              5140963.637608036
            ],
            [
              -9753590.270998942,
              5140962.385484382
            ],
            [
              -9753589.946512932,
              5140944.8207042115
            ],
            [
              -9753522.380848315,
              5140946.068890223
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "id": "americas",
      "properties": {},
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -9753441.024704574,
              5140930.230001994
            ],
            [
              -9753357.047235476,
              5140931.798873299
            ],
            [
              -9753356.580225041,
              5140906.483729275
            ],
            [
              -9753346.211058,
              5140906.675018299
            ],
            [
              -9753348.278336572,
              5141018.735587054
            ],
            [
              -9753429.200153632,
              5141017.24275206
            ],
            [
              -9753428.877633499,
              5140996.467543021
            ],
            [
              -9753456.915628737,
              5140995.950301788
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "id": "hall_7",
      "properties": {
        "id": "hall_7"
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -9753455.138466632,
              5140947.720013722
            ],
            [
              -9753398.643823106,
              5140949.00079149
            ],
            [
              -9753398.70731005,
              5140961.763202369
            ],
            [
              -9753440.210215932,
              5140961.173639909
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "id": "nature_walk",
      "properties": {},
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -9753513.479440464,
              5140999.179808604
            ],
            [
              -9753602.656095784,
              5140997.528423689
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "id": "mammals",
      "properties": {},
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -9753615.307627132,
              5140909.67892379
            ],
            [
              -9753616.61971829,
              5140980.533332031
            ],
            [
              -9753529.368393824,
              5140982.14906342
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "id": "mammals",
      "properties": {},
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -9753617.383617105,
              5141021.784732976
            ],
            [
              -9753616.700627785,
              5140984.902536322
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "id": "animals",
      "properties": {},
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -9753608.016991979,
              5140921.309949971
            ],
            [
              -9753544.228961019,
              5140922.491185035
            ],
            [
              -9753544.436671322,
              5140933.70777657
            ],
            [
              -9753607.35362318,
              5140932.542672259
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "id": "hall_5",
      "properties": {},
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              -9753456.406666253,
              5140982.372656139
            ],
            [
              -9753391.862374734,
              5140983.563360703
            ],
            [
              -9753391.529350076,
              5140970.725886919
            ],
            [
              -9753440.770093296,
              5140970.11638344
            ]
          ]
        ]
      }
    }
  ]
}
