{
"type": "FeatureCollection",
"name": "ground_level_labels",
"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::3857" } },
"features": [
{ "type": "Feature", "id": "egypt", "properties": { }, "geometry": { "type": "Point", "coordinates": [ -9753483.689790990203619, 5140944.760975550860167 ] } },
{ "type": "Feature", "id": "underground", "properties": { }, "geometry": { "type": "Point", "coordinates": [ -9753402.28125, 5140954.526322099380195 ] } },
{ "type": "Feature", "id": "simpson_theater", "properties": { }, "geometry": { "type": "Point", "coordinates": [ -9753585.738046113401651, 5140962.645689006894827 ] } },
{ "type": "Feature", "id": "playlab", "properties": { }, "geometry": { "type": "Point", "coordinates": [ -9753409.141499124467373, 5140982.048988507129252 ] } },
{ "type": "Feature", "id": "harris", "properties": { }, "geometry": { "type": "Point", "coordinates": [ -9753582.847775518894196, 5140996.542178218252957 ] } },
{ "type": "Feature", "id": "biomechanics", "properties": { }, "geometry": { "type": "Point", "coordinates": [ -9753402.451138392090797, 5140929.462015461176634 ] } }
]
}
